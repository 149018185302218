
app-navbar {
  border-bottom: 1px solid $accent1;

  a {
    text-decoration: underline $accent1;
    color: $text1;
    padding-bottom: 0.25rem;
  }

  svg {
    width: $icon-size1;
    height: $icon-size1;
    min-width: $icon-size1;
    min-height: $icon-size1;
    max-width: $icon-size1;
    max-height: $icon-size1;
  }

}
