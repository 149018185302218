@import "styles/colors";
@import "styles/sizes";
@import "styles/fonts";

@import "app/app.theme";

* {
  font-family: Consolas;
}

body {
  background-color: $background;
  margin: 0 !important;
  height: 100vh;
}
