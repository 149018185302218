
.home-card {
  border: 1px solid $accent1;

  width: 20rem;

  .home-card-inner {
    margin: 1rem;
    position: relative;


    .home-card-image {
      position: absolute;
      top: -5rem;
      left: 5rem;
      background-color: white;
      border-radius: 50%;
      border: 1px solid #d6d6d6;
      width: 8rem;
      height: 8rem;
    }

    .home-card-content-container {
      padding-top: 2rem;

      .home-card-header {
        text-align: center;


      }

      .home-card-content {

      }
    }
  }
}
